import React, { useMemo } from "react";
import { graphql } from "gatsby";

import SEO from "../../../components/seo";
import PortableText from "../../../components/portableText";
import { SymbolsSection, idify } from "../../../components/public-symbol-page";
import FilterFor from "../../../components/FilterFor";
import { useState } from "react";
import SearchUI from "../../../components/search/SearchUI";

export const query = graphql`
  query AllSymbolsPageFrQuery {
    page: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawSymbolsPageAllIntroductionFrench(resolveReferences: { maxDepth: 5 })
      _rawSymbolsPageAllIntroduction(resolveReferences: { maxDepth: 5 })
      _rawSymbolsPageConclusionFrench(resolveReferences: { maxDepth: 5 })
      _rawSymbolsPageConclusion(resolveReferences: { maxDepth: 5 })
    }
    symbols: allSanitySymbol(
      filter: { hidden: { eq: false } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title_french
        title
        category
        slug: slugFr {
          current
        }
        mainImage {
          ...SanityImage
          alt_french
          alt
          caption_french
          caption
        }
        _rawDescriptionFrench(resolveReferences: { maxDepth: 5 })
        _rawDescription(resolveReferences: { maxDepth: 5 })
      }
    }
  }
`;

function Section({ title, description, symbols, category }) {
  const sectionSymbols = symbols[category];
  return (
    <>
      <div className="prose dark:prose-invert prose-lg pt-3">
        <h2 id={`all-${category}`}>{title}</h2>
        {description && <div className="italic">{description}</div>}
      </div>
      <SymbolsSection symbols={sectionSymbols} isSubSection={true} />
    </>
  );
}

const AllSymbolsPageFr = ({ data = {} }) => {
  const introduction =
    data.page &&
    (data.page._rawSymbolsPageAllIntroductionFrench ||
      data.page._rawSymbolsPageAllIntroduction);

  const symbolsWithId = useMemo(
    () =>
      data.symbols.nodes
        .map((symbol) => {
          const title = symbol.title_french || symbol.title;
          const description =
            symbol._rawDescriptionFrench || symbol._rawDescription;
          const alt = symbol.mainImage?.alt_french || symbol.mainImage?.alt;
          const caption =
            symbol.mainImage?.caption_french || symbol.mainImage?.caption;
          return {
            ...symbol,
            id: idify(title),
            title,
            mainImage: symbol.mainImage && {
              ...symbol.mainImage,
              alt,
              caption,
            },
            description,
          };
        })
        .sort((a, b) => a.title.localeCompare(b.title, "fr-CA")),
    [data.symbols.nodes]
  );

  const [symbols, setSymbols] = useState(symbolsWithId);

  const conclusion =
    data.page &&
    (data.page._rawSymbolsPageConclusionFrench ||
      data.page._rawSymbolsPageConclusion);

  return (
    <>
      <SEO title="Liste complète" />
      <div className="prose dark:prose-invert prose-lg md:pt-12">
        <h1>Guide à la haine en ligne: liste complète</h1>
        {introduction && (
          <div className="pt-1 md:pt-2">
            <PortableText blocks={introduction} />
          </div>
        )}
      </div>
      <SearchUI isFrench />
      <FilterFor symbols={symbolsWithId} setSymbols={setSymbols} isFrench />
      <SymbolsSection symbols={symbols} isFrench={true} />

      {conclusion && (
        <div className="prose dark:prose-invert prose-lg pt-4 pb-16 md:pt-8 italic text-center md:text-left">
          <PortableText blocks={conclusion} />
        </div>
      )}
    </>
  );
};

export default AllSymbolsPageFr;
